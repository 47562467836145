<template>
  <template v-if="questionsProp">
    <ion-row class="main">
      <ion-col>
        <ion-row class="header">
          <ion-row class="progress w-100 ion-justify-content-start">
            <ion-col
              v-for="item in questionsProp"
              :key="item.id"
              class="progress-indicator"
              :class="{
                active: item.ordine <= currentQuestion,
                inactive: item.ordine > currenQuestion,
              }"
              >{{ item.ordine }}
            </ion-col>
          </ion-row>
        </ion-row>

        <ion-row class="body" style="flex-grow: 1">
          <ion-col>
            <Question
              @nextQuestion="nextQuestion"
              :max_time="resFromApi.max_time"
              :question="questionsProp[currentQuestion]"
              :answers="questionsProp[currentQuestion].answers"
              :finito="finito"
            ></Question>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </template>
</template>

<script>
import Question from "./Question.vue"

import {
  IonGrid,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonCol,
  IonCard,
  IonItem,
  IonList,
  IonLabel,
  alertController,
  IonicSafeString,
  modalController,
} from "@ionic/vue"
import ApiService from "../../common/service.api"
import { translate } from "../../common/helper.auth"
import ModalQuizResult from "../../views/Modals/ModalQuizResult.vue"

import UserService from "../../common/mixins/User"

export default {
  name: "Quiz",
  components: {
    IonGrid,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonCard,
    IonItem,
    IonList,
    IonLabel,
    Question,
  },
  props: { resFromApi: Object, parentParams: Object },
  mixins: [UserService],
  ionViewWillEnter() {
    this.loadUser()
  },
  data() {
    return {
      finito: false,
      progressArr: [],
      score: 0,
      questionsProp: null,
      currentQuestion: 0,
      user: null,
    }
  },
  mounted() {
    this.finito = false
    this.progressArr.length = 0
    this.score = 0
    this.questionsProp = null
    this.currentQuestion = 0
    this.user = null
    this.questionsProp = this.resFromApi.questions
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: ModalQuizResult,
        cssClass: "my-custom-class",
        componentProps: {
          score: this.score,
          maxScore: this.questionsProp.length,
          user: this.user,
        },
      })
      return modal.present()
    },
    nextQuestion(scored) {
      scored ? this.score++ : this.score
      if (this.currentQuestion + 1 >= this.questionsProp.length) {
        this.finito = true

        this.loadUser()
      } else {
        this.currentQuestion++
      }
    },
    loadUser() {
      this.getCurrentUser().then((data) => {
        this.user = data
        this.openModal()
        this.currentQuestion = 0
        this.finito = false
      })
    },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid rgb(237, 255, 211);
} */

template {
  height: 100%;
}

.header {
  width: 100%;
  transform: translate(-20px);
  background: white;
  margin: 0 auto;
  top: 40px;
  z-index: 100;
}

.w-100 {
  width: 100%;
}

.progress-indicator,
.inactive {
  max-width: 100px;
  width: 50px;
  height: 20px;
  margin: 1rem 0.2rem;
  color: var(--ion-color-primary);
  background: #ecedff;
  font-size: 0.7rem;
}

.active {
  width: 50px;
  height: 20px;
  margin: 1rem 0.2rem;
  color: #ecedff;
  background: var(--ion-color-primary);
  border: #ecedff;
  font-size: 0.7rem;
}
</style>
