<template>
  <ion-row class="ion-justify-content-center w-100">
    <ion-col class="w-100">
      <ion-row class="w-100 ion-align-items-center ion-justify-content-center">
        <ion-col v-if="this.max_time" size="auto">
          <div
            class="timer-container"
            :class="{
              'pulser-green': (secondsLeft / 10).toFixed(0) > redTimer,
              'pulser-red':
                (secondsLeft / 10).toFixed(0) <= redTimer &&
                (secondsLeft / 10).toFixed(0) > 0,
              'pulser-end': (secondsLeft / 10).toFixed(0) <= 0,
            }"
          >
            <ve-progress
              class="ve-progress"
              :class="this.finito ? 'hidden' : ''"
              :size="timerSize"
              :color="timerColor"
              emptyColor="#FF000000"
              thickness="5"
              emptyThickness="1"
              :hideLegend="true"
              :progress="(10 * secondsLeft) / max_time"
              animation="default 0 0"
            />

            <div class="timer-legend">
              <p>{{ (secondsLeft / 10).toFixed(0) }}</p>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="quiz-question quiz">
        <ion-col>
          <ion-row v-if="question.image_url">
            <ion-col>
              <img :src="question.image_url" @error="handleImgError" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              {{ $root.translate(question.question) }}
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row class="answers-container ion-no-padding">
        <ion-col size="12" class="ion-no-padding ion-no-margin">
          <div class="answers-wrapper ion-padding-top ion-margin-top">
            <ion-row
              v-for="(item, index) in answers"
              :key="index"
              id="option"
              class="ion-align-items-center quiz quiz-item"
              :ref="optionChosen"
              @click="selectAnswer(item, index)"
            >
              <ion-col class="ion-no-padding ion-no-margin">
                <ion-row v-if="item.image_url">
                  <ion-col>
                    <img :src="item.image_url" @error="handleImgError" />
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col> {{ $root.translate(item.answer) }}</ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonProgressBar,
  IonGrid,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonCol,
  IonCard,
  IonItem,
  IonList,
  IonLabel,
} from "@ionic/vue";
import ApiService from "../../common/service.api";

export default {
  name: "Question",
  props: {
    max_time: Number,
    question: Object,
    answers: Array,
    finito: Boolean,
  },
  components: {
    IonProgressBar,
    IonGrid,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonCard,
    IonItem,
    IonList,
    IonLabel,
  },
  data() {
    return {
      timerSize: 50,
      redTimer: 5,
      itemsRef: [],
      canClick: true,
      scored: 0,
      secondsLeft: 0,
      percLeft: 1,
      interval: null,
      statistics: [],
    };
  },

  mounted() {
    // setTimeout(this.setSameHeigth, 100);

    this.secondsLeft = this.max_time * 10;
    this.timer();
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    setAutoHeight() {
      let array = document.querySelectorAll("[id=option]");

      array.forEach((element) => {
        element.style.height = "auto";
      });

      array.forEach((element) => {
        let currentHeight = parseInt(
          getComputedStyle(element).height.slice(0, -2)
        );
        console.log("CURRENT inside AUTO ", element.style.height);
      });

      // setTimeout(this.setSameHeigth(), 2000);
    },
    setSameHeigth() {
      let array = document.querySelectorAll("[id=option]");
      let maxHeight = 0;
      array.forEach((element) => {
        element.style.height = "";
        let currentHeight = parseInt(
          getComputedStyle(element).height.slice(0, -2)
        );
        currentHeight >= maxHeight ? (maxHeight = currentHeight) : null;
      });

      array.forEach((element) => {
        element.style.height = maxHeight + "px";
      });
    },
    optionChosen(element) {
      if (element) {
        this.itemsRef.push(element);
      }
    },
    async selectAnswer(item, index) {
      ApiService.post(
        "CampaignManager/RegisterSurveyAnswer",
        {},
        {
          params: {
            questionID: this.question.id,
            answerID: item.id,
          },
        }
      ).then((res) => {});
      if (!this.finito) {
        const itemContainer = this.itemsRef[index].$el;
        if (this.percLeft == 0) {
          this.canClick = false;
          this.resetStyles(itemContainer);
        } else {
          if (this.canClick) {
            this.canClick = false;
            if (item.isCorrect) {
              itemContainer.classList.add("quiz-item-correct");
              this.scored = 1;
            } else {
              itemContainer.classList.add("quiz-item-wrong");
            }
            this.resetStyles(itemContainer);
          }
        }
      }
    },
    nextQuestion() {
      this.$emit("nextQuestion", this.scored);
      this.scored = 0;
      this.secondsLeft = this.max_time * 10;
      this.percLeft = 1;
      clearInterval(this.interval);
      this.timer();
    },
    resetStyles(itemContainer) {
      setTimeout(() => {
        this.canClick = true;
        itemContainer.classList.remove("quiz-item-correct");
        itemContainer.classList.remove("quiz-item-wrong");
        if (!this.finito) {
          this.nextQuestion();
          // this.setAutoHeight();
        }
      }, 1000);
    },
    timer() {
      this.interval = setInterval(() => {
        if (this.secondsLeft > 0) {
          this.secondsLeft--;
          this.percLeft = this.secondsLeft / (this.max_time * 10);
        } else {
          clearInterval(this.interval);
        }
      }, 100);
    },
  },
  computed: {
    timerColor() {
      if ((this.secondsLeft / 10).toFixed(0) >= this.redTimer + 1) {
        return "green";
      } else {
        return "red";
      }
    },
  },
  watch: {
    question: function () {
      console.log("Question has changed");
      // this.setSameHeigth();
    },
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid rgb(255, 206, 187);
} */

.e {
  border: 10px solid greenyellow;
}

.w-100 {
  width: 100%;
}

.quiz {
  background: var(--ion-color-primary);
  color: #ecedff;
  border-radius: 2rem;
  margin: 0.5rem;
  /* font-weight: bold; */
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
}

.quiz-question {
  padding: 1.5rem 0.6rem;
  /* font-weight: bold; */
}

.answers-container {
  margin-top: 1.5rem;
}

.answers-wrapper {
  display: grid;
  grid-auto-rows: 1fr;
}

.quiz-item {
  text-align: center;
  height: auto;
  background: var(--ion-color-primary);
  /* font-weight: bold; */
}

.quiz-item:hover {
  cursor: pointer;
}

.quiz-item-wrong {
  background: #da0808;
  color: #000000;
}

.quiz-item-correct {
  background: #08c748;
  color: #000000;
}

.hidden {
  --progress-background: #ecedff;
}

.timer-container {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1000;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  background-color: var(--ion-color-primary);
}

.ve-progress {
  position: relative;
}

.timer-legend {
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translate(-50%, 50%);
  color: var(--text-color-light);
  font-size: 1.5rem;
  font-weight: bold;
}

.pulser-green,
.pulser-red {
  background-color: var(--ion-color-primary);
}

.pulser-green::before,
.pulser-red::before {
  content: "";
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  animation: pulse 1000ms infinite;
}

.pulser-green::before {
  background-color: var(--ion-color-primary);
}

.pulser-red::before {
  background-color: rgba(180, 16, 16, 0.555);
}

.pulser-end {
  background-color: rgb(73, 0, 0);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }

  45% {
    transform: scale(1.3);
    opacity: 0.5;
  }

  55% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}
</style>
